.radio-group__button-group {
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
  justify-content: center;
}

button.radio-group__button {
  font-size: 1rem;
  min-width: 200px;
  box-sizing: border-box;
  border: 1px solid blue;
  border-radius: 8px;
  background-color: Transparent;
  outline: none;
  padding: 16px 32px;
  color: blue;
}

button.radio-group__button[aria-checked="true"] {
  background-color: #4d4dff;
  color: white;
}

button:hover {
  background-color: #0000e6;
  color: white;
}